@import ../node_modules/fontawesome-4.7/css/font-awesome.min
@import ../node_modules/normalize.css/normalize
@import ../node_modules/hamburgers/dist/hamburgers
@import ../node_modules/swiper/swiper-bundle.min
@import ../node_modules/aos/dist/aos

@import ../node_modules/intl-tel-input/build/css/intlTelInput.min

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap')

$color-primary-0: #2D3C1B
$color-secondary-0: #A8763E
$color-accent-0: #8A0100
$color-dark: #22160d
$color-light: #F7F3E3

*
    box-sizing: border-box
    &::before
        box-sizing: border-box
    &::after
        box-sizing: border-box

html
    scroll-behavior: smooth

body
    overflow-x: hidden
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-color: $color-dark
    color: $color-dark
    font-family: 'Sora', sans-serif
    padding: 0 16px
    font-size: min(calc( 1.2vw + 8px ), 18px)

    h1
        color: $color-accent-0
        font-weight: 600
        text-transform: uppercase
        letter-spacing: 1.2
    h3
        color: $color-primary-0

    p
        max-width: 60ch
        line-height: 1.6
        margin: 2rem auto

main
    padding: 24px
    background-color: $color-light
    display: flex
    gap: 2rem
    width: 100%
    max-width: 1024px
    margin: 0 auto
    span
        .hidden
            display: none
        .visible
            display: block
    .content
        margin: 16px auto
    p
        text-align: justify
    h1, h3
        text-align: center

    form
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        gap: 1rem
        width: 100%
        max-width: 400px
        margin: auto

        .iti, #phone
            width: 100%

        input
            border: 2px solid $color-accent-0
            border-radius: 3px
            padding: 6px
            text-transform: uppercase
        .wide
            width: 100%

#logo
    background-image: url("./images/bg1.jpg")
    background-attachment: fixed
    width: 100%
    background-size: cover
    background-position: center bottom
    height: min(75vw, 90vh)
    mask-image: url(images/logo.svg)
    mask-repeat: no-repeat
    mask-position: center
    mask-size: min(60%, 80vh)

#footer
    text-align: center
    margin-top: 2rem
    background-color: $color-dark
    width: 100%
    color: $color-secondary-0
    padding: 16px 0
    .logos
        display: grid
        gap: 24px 12px
        grid-template-columns: 1fr 1fr
        align-items: center
        flex-wrap: wrap
        max-width: 1024px
        margin: 0 auto
        img
            max-width: min(40vw, 120px)
            max-height: 64px
            object-fit: contain
            margin: 0 auto
        @media screen and ( min-width: 360px )
            grid-template-columns: 1fr 1fr 1fr
        @media screen and ( min-width: 640px )
            grid-template-columns: repeat(6, 1fr)

.j-button
    margin: 1rem 0
    height: 2rem
    background: none
    position: relative
    border: none
    cursor: pointer
    line-height: 2rem
    text-transform: uppercase
    font-weight: 500
    color: $color-accent-0
    z-index: 1
    &:disabled
        opacity: 0.5
        cursor: not-allowed
    span
        position: relative
        color: white
        z-index: 400
    &::before
        transition: all 0.2s ease
        z-index: 0
        position: absolute
        content: ""
        display: block
        height: 2rem
        top: 0
        background-color: $color-accent-0
        left: 0
        width: 100%
        border-radius: 2px
    &:hover, &:focus
        &:not(:disabled)

            span
                color: $color-dark
            &::before
                left: calc( 50% - 1.5rem )
                top: -0.5rem
                border-radius: 50%
                width: 3rem
                height: 3rem
